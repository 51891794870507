@import (reference) './vars.less';
@import '../../../../../node_modules/@unifii/library/styles/tables.less';

uf-table .table-header-cell .table-header-label {
    font-family: @font-family-secondary;
    font-size: @font-size-sm;
    font-weight: normal;
}

// fix for opensans
.uf-table th {
    font-weight: 600;
}

.uf-table.fixed {
    table-layout: fixed;
}


.uf-table tbody {
    tr {
        td {
            uf-checkbox {
                --checkbox-padding: 0;
            }
        }
    }
}